.search-main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
    width: 100%;
}

.search-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, 160px);
    row-gap: 10px;
    column-gap: 5px;
    justify-content: center;
    width: 100%;
}

.search-title-container {
    border-style: solid;
    border-width: 3px;
    border-color: rgb(55, 130, 175);
    background-color: rgb(55, 130, 175, 0.25);
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 700px;
    border-radius: 15px;
    padding-left: 25px;
    padding-right: 25px;
    margin-bottom: 0;
}

.search-title {
    color: black;
    width: 100%;
}

.pages-data {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
}

.page-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgb(55, 130, 175);
    border-style: solid;
    border-radius: 15px;
    width: 100px;
    height: 50px;
    margin-left: 10px;
    margin-right: 10px;
    transition: 0.5s;
}

.page-button-icon {
    font-size: xx-large;
}

.search-filters-container {
    margin-bottom: 25px;
    margin-top: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.search-filter-button {
    height: 45px;
    width: 95px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    background-color: transparent;
    border-style: solid;
    border-color: rgb(55, 130, 175);
    border-top-style: none;
    margin-left: 2px;
    margin-right: 2px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    transition: 0.5s;
}

@media (min-width: 800px) {
    .page-button:hover {
        cursor: pointer;
        background-color: rgb(55, 130, 175, 0.5);
    }

    .page-button:disabled:hover {
        cursor: default;
        background-color: rgb(55, 130, 175);
    }

    .search-filter-button:hover {
        cursor: pointer;
        background-color: rgb(55, 130, 175, 0.75);
    }
}

@media (max-width: 800px) {
    .search-title-container {
        max-width: 80vw;
    }

    .search-title {
        max-width: 75vw;
    }
}