.nav-main{
    display: flex;
    flex-direction: row;
    align-self: center;
    height: 125px;
    max-height: 125px;
    width: 100%;
    box-shadow: 0px 5px 5px 1px black;
}

.nav-column{
    display: flex;
    flex-direction: column;
}

.sides{
    width: 25%;
}

.center{
    width: 50%;
    border-right-style: none;
    border-width: 3px;
    border-right-color: rgb(55, 130, 175);
}

.mobile{
    display: none;
}

.logo{
    max-height: 125px;
    width: auto;
}

.nav-search{
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-end;
}

.search{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.search-input{
    width: 250px;
    max-width: 250px;
    align-self: center;
    height: 25px;
    background-color: rgb(55, 130, 175, 0.5);
    border-color: black;
    border-style: solid;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    padding: 2px;
    padding-left: 10px;
}

.search-input:focus{
    outline: none;
}

.search-button{
    display: flex;
    flex-direction: column;
    width: fit-content;
    height: 100%;
    align-content: center;
    justify-content: center;
    align-self: center;
    background-color: transparent;
    border-style: solid;
    border-color: black;
    border-left-style: none;
    padding: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    transition: 0.5s;
}

.button-text{
    text-align: center;
    margin: 0;
    width: fit-content;
    align-self: center;
    font-weight: bold;
}

.advanced{
    margin: 0;
    margin-bottom: 25px;
    margin-top: 5px;
    font-weight: bolder;
    font-size: 15px;
    color: rgb(5, 80, 125);
    width: fit-content;
    align-self: center;
    transition: 0.5s;
}

.nav{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.nav-mobile{
    display: flex;
    flex-direction: row;
}

.nav-button{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 125px;
    border-style: solid;
    border-bottom-style: none;
    border-width: 2px;
    height: 30px;
    margin-left: 5px;
    margin-right: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    transition: 0.5s;
}

@media (min-width: 1000px){
    .search-button:hover{
        cursor: pointer;
        background-color: rgb(55, 130, 175);
    }

    .advanced:hover{
        text-decoration: underline;
    }    
    
    .nav-button:hover{
        cursor: pointer;
        color: white;
        background-color: black;
        border-color: black;
    }
}

@media (min-width: 950px) and (max-width: 1115px){
    #contact{
        display: none
    }
}

@media (max-width: 950px){
    .advanced{
        margin-top: -10px;
        margin-bottom: 10px;
    }    

    .search{
        margin-bottom: 5px;
    }

    .nav{
        flex-direction: column;
    }

    .center{
        border-style: none;
    }

    .nav-mobile{
        width: fit-content;
        align-self: center;
    }

    .nav-button{
        border-width: 1px;
        border-style: solid;
        border-radius: 10px;
        margin-top: 2px;
        margin-bottom: 2px;
    }

    .mobile{
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: xx-large;
        margin-top: 15px;
    }

    .mobile-icon{
        border-radius: 50%;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .nav-main{
        padding-bottom: 10px
    }
}

@media (min-width: 800px) and (max-width: 950px) {
    .nav-main{
        justify-content: flex-start;
    }

    .sides{
        width: auto;
    }

    .center{
        width: auto;
    }

    .nav-column{
        margin-left: 5px;
        margin-right: 5px;
    }

    .nav-search{
        width: 375px;
        margin: 0;
        padding: 0;
    }
}

@media (max-width: 800px){
    .nav-main{
        flex-direction: column;
        height: fit-content;
        max-height: fit-content;
        align-content: center;
        justify-content: center;
    }

    .logo{
        max-width: 100%;
        height: auto;
    }

    .sides{
        margin: 0;
        width: 100%;
    }

    .center{
        height: 150px;
    }

    .mobile{
        flex-direction: row;
    }

    .mobile-icon{
        margin: 0;
        margin-left: 5px;
        margin-right: 5px;
    }

    .nav-column{
        width: 100%;
        align-self: center;
    }

    .search{
        max-width: 90%;
        align-self: center;
        margin-bottom: 15px;
    }
    
    .nav-button{
        border-width: 2px;
    }
}