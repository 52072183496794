.checkout-main{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 23px;
}

.form-checkout-style{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}