/* Estilos personalizados para SweetAlert */

/* Modifica el fondo del modal */
.swal2-popup {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    background-color: white;
    border-color: black;
    border-style: solid;
    box-shadow: 5px 5px 0 0 black;
    border-radius: 0;
}