.build-main-container{
    display: flex;
    flex-direction: column;
    margin-top: 2em;
}

.title-container{
    display: flex;
    flex-direction: column;
    background-color: rgb(55, 130, 175);
    border-radius: 1em;
    max-width: 75vw;
    align-self: center;
    width: 75vw;
}

.title{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    text-align: center;
    align-self: center;
    color: white;
}