a{
    text-decoration: none;
    color: black;
}

.mainComponent{
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.scrollbar{
    padding-bottom: 10px;
}

.scrollbar::-webkit-scrollbar {
    max-width: 15px; /* Ancho de la barra de desplazamiento */
    height: 15px; /* Altura de la barra de desplazamiento */
}

/* Estilo para el riel de la barra de desplazamiento */
.scrollbar::-webkit-scrollbar-track {
    background-color: transparent; /* Color de fondo del riel */
}

/* Estilo para el pulgar de la barra de desplazamiento */
.scrollbar::-webkit-scrollbar-thumb {
    background-color: rgb(55, 130, 175); /* Color del pulgar */
    border: none; /* Sin borde */
    border-radius: 15px; /* Sin borde redondeado */
}

.scrollbar:hover::-webkit-scrollbar-thumb{
    border-style: solid;
    border-color: black;
    border-width: thin;
    cursor: grab;
}

.main-scrollbar{
    padding-bottom: -50px;
}

.main-scrollbar::-webkit-scrollbar {
    max-width: 15px; /* Ancho de la barra de desplazamiento */
    height: 15px; /* Altura de la barra de desplazamiento */
}

/* Estilo para el riel de la barra de desplazamiento */
.main-scrollbar::-webkit-scrollbar-track {
    background-color: transparent; /* Color de fondo del riel */
}

/* Estilo para el pulgar de la barra de desplazamiento */
.main-scrollbar::-webkit-scrollbar-thumb {
    background-color: rgb(55, 130, 175); /* Color del pulgar */
    border: none; /* Sin borde */
    border-radius: 0; /* Sin borde redondeado */
}

.main-scrollbar:hover::-webkit-scrollbar-thumb{
    cursor: grab;
}