.recos-main-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(to bottom, rgb(5, 80, 125, 0.75), rgb(5, 80, 125, 0.75) 40%, white 40%);
    max-width: fit-content;
    padding-right: 15px;
    padding-left: 15px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    
    border-width: 2px;
}

.recos-main-grid{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    max-width: 490px;
    align-self: center;
}

.recos-title{
    color: white;
    padding: 10px;
    padding-right: 25px;
    padding-left: 25px;
    border-radius: 15px;
}

@media (max-width: 500px){
    .recos-main-grid{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 10px;
        max-width: 330px;
        align-self: center;
    }
}