.banner-container{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.news-container{
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
}

.news-title-main{
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    width: 100%;
    height: fit-content;
}

.news-bar{
    min-height: 35px;
    max-height: 35px;
    width: 400px;
    background-color: black;
    align-self: center;
}

.izq{
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.der{
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.news-title-container{
    background-color: black;
    padding-left: 50px;
    padding-right: 50px;
    color: rgb(155, 230, 275);
    border-radius: 10px;
    border-style: solid;
    border-color: white;
}

.news-grid{
    display: grid;
    grid-template-columns: repeat(auto-fill, 220px);
    row-gap: 12px;
    column-gap: 5px;
    justify-content: center;
    margin-top: 15px;
    place-items: center;
    max-height: 340px;
    border-style: solid;
    border-color: black;
    padding: 10px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    background-color: rgb(5, 80, 125, 0.65);
}

.bs-container{
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media (max-width: 800px){

    .news-grid{
        border-radius: 10px;
    }

    .news-title-container{
        padding-left: 20px;
        padding-right: 20px;
    }

    .news-title{
        font-size: x-large;
    }

    .news-bar{
        width: 50px;
    }

    .news-grid{
        row-gap: 25px;
    }
    
}