.serie-page-main-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
    width: 100%;
}

.serie-page-error-title{
    background-color: black;
    color: white;
    padding: 15px;
    width: 90vw;
    border-radius: 15px;
    margin-bottom: 0;
}

.serie-title-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgb(5, 80, 125);
    color: white;
    width: 90vw;
    max-width: 850px;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    border-style: double;
    border-bottom-style: none;
    align-self: center;
    border-width: 10px;
    margin-bottom: 2px;
}

.serie-info-main-container{
    display: grid;
    grid-template-columns: repeat(auto-fill, 500px);
    row-gap: 10px;
    column-gap: 10px;
    justify-content: center;
    width: 100%;
    align-items: center;
    justify-items: center;
}

.serie-info-container{
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 500px;
    max-width: 90vw;
    height: 400px;
    border-style: solid;
    border-color: rgb(55, 130, 175);
    background-color: rgb(55, 130, 175, 0.25);
}

.serie-info-title{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgb(55, 130, 175);
    margin-top: 0;
    width: 85%;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    height: 35px;
}

.serie-info{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.serie-info-span{
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: flex-start;
    align-items: flex-end;
    margin-bottom: 5px;
    margin-top: 5px;
}

.serie-info-span-title{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 150px;
    margin: 5px;
    margin-bottom: 0;
    font-weight: bolder;
    font-size: large;
    height: 100%;
}

.serie-info-span-text{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 150px;
    margin: 5px;
    margin-bottom: 0;
    height: 100%;
    padding-left: 5px;
    border-radius: 5px;
    text-align: center;
    transition: 0.5s;
}

.serie-info-sinopsis-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-left: 20px;
    padding-right: 20px;
    height: 80%;
    overflow-y: auto;
}

.serie-volumes-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-top-style: solid;
    border-radius: 20px;
    border-color: rgb(55, 130, 175);
    width: 85%;
    margin-top: 15px;
    padding-bottom: 15px;
}

.serie-volumes-title{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgb(55, 130, 175);
    margin-top: 0;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    width: 200px;
    height: 40px;
}

.serie-volumes-grid{
    display: grid;
    grid-template-columns: repeat(auto-fill, 160px);
    row-gap: 10px;
    column-gap: 10px;
    justify-content: center;
    width: 100%;
    align-items: center;
    justify-items: center;
}

@media (min-width: 800px){
    .serie-link:hover{
        cursor: pointer;
        background-color: rgb(55, 130, 175);
    }
}

@media (max-width: 550px){

    .serie-title-container{
        max-width: 80vw;
        border-radius: 20px;
        border-style: double;
    }

    .serie-info-container{
        border-radius: 20px;
    }

    .serie-info{
        padding-left: 25px;
    }

    .serie-info-sinopsis{
        font-size: small;
    }

    .serie-info-span-title{
        font-size: medium;
        width: 125px;
    }

    .serie-info-span-text{
        font-size: small;
        width: 125px;
    }

    .info{
        max-height: 325px;
    }

    .serie-volumes-container{
        width: 100%;
    }
}