.dash-form-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    border-style: none;
    box-shadow: 2px 2px 3px 1px black;
    padding: 5px;
    border-radius: 10px;
    width: 450px;
    margin-left: 5px;
    margin-right: 5px;
}

.other-vols{
    width: 100%;
    background-color: white;
    display: grid;
    grid-template-columns: repeat(auto-fill, 85px);
    justify-content: center;
    column-gap: 5px;
    row-gap: 2px;
    margin-top: 10px;
    max-height: 260px;
    overflow-y: scroll;
    border-radius: 10px;
}

.other-vols-img{
    width: 85px;
    height: 125px;
    object-fit: cover;
    margin: 2px;
    border-radius: 5px;
}

.dash-form-space{
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: center;
}

.dash-form-title{
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgb(5, 80, 125);
    width: 275px;
    height: 35px;
    color: white;
    border-radius: 10px;
}

.dash-form-column{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 25px;
    margin-left: 5px;
    margin-right: 5px;
    padding: 10px;
    border-radius: 10px;
    background-color: rgb(0, 0, 0, 0.05);
}

.dash-form-label{
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 10px;
}

.dash-form-input{
    text-align: center;
    width: 175px;
    height: 20px;
    padding: 5px;
    border-radius: 5px;
    background-color: rgb(55, 130, 175, 0.5);
    border-style: solid;
    border-color: transparent;
    box-sizing:content-box;
    align-self: center;
}

.dash-form-input:disabled{
    background-color: rgb(55, 130, 175, 0.1);
}

.dash-form-input:focus{
    outline: none;
}

.first-label{
    margin-top: 0;
}

option{
    background-color: white;
}

.input-nombre{
    width: 325px;
    height: 35px;
    max-width: 325px;
    max-height: 35px;
}

.input-precio{
    width: 85px;
    height: 10px;
    max-width: 85px;
    max-height: 10px;
    margin-left: 2px;
    margin-right: 2px;
}

.input-envio{
    margin-bottom: 5px;
    margin-top: 5px;
    margin: 5px;
}

.envio-title{
    margin-bottom: 0;
}

.dash-form-checks{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: small;
}

.no-margin{
    margin: 5px;
}

.input-check{
    width: fit-content;
    height: fit-content;
    margin-right: 5px;
}

.area{
    resize: none;
    height: 125px;
    padding: 15px;
}

input[type="file"] {
    display: none;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

input[type=number] { -moz-appearance:textfield; }

.cover{
    border-style: solid;
    border-width: 2px;
    border-color: transparent;
    background-color: rgb(55, 130, 175, 0.5);
    border-radius: 5px;
    padding: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    transition: 0.5s;
    width: fit-content;
    align-self: center;
}

.cover:hover{
    cursor: pointer;
    border-color: rgb(5, 80, 125);
}

.dash-form-img{
    width: 150px;
    height: 200px;
    object-fit: cover;
    align-self: center;
    border-radius: 10px;
}

.dash-form-button{
    margin-top: 15px;
    width: 225px;
    height: 35px;
    border-style: solid;
    border-color: white;
    background-color: rgb(55, 130, 175);
    border-radius: 5px;
    font-weight: bold;
    font-size: larger;
    transition: 0.5s;
}

.dash-form-button:hover{
    cursor: pointer;
    border-color: black;
}

.dash-form-button:disabled{
    cursor: default;
    background-color: gray;
    color: rgb(75, 75, 75);
}

.dash-form-button:disabled:hover{
    cursor: default;
    background-color: gray;
    border-color: rgb(75, 75, 75);
    color: rgb(75, 75, 75);
}

.text-area{
    height: 175px;
    resize: none;
}

.add-item{
    font-size: medium;
    width: 150px;
    height: 30px;
    font-weight: normal;
    background-color: rgba(5, 80, 125);
    margin-bottom: -10px;
    color: white;
}

.dash-form-double{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

@media (max-width: 500px){
    .dash-form-container{
        width: 325px;
        align-self: center;
    }

    .dash-form-space{
        flex-direction: column;
    }

    .dash-form-column{
        width: 300px;
        align-items: center;
    }

    .dash-form-input{
        width: 150px;
        height: 35px;
    }

    
    input[type="text"] {
        width: 225px;
    }
    
    .input-nombre{
        width: 225px;
    }

    .text-area{
        width: 275px;
        height: 175px;
        resize: none;
    }
}