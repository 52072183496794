.ventas-card-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 250px;
    height: 300px;
    border-style: solid;
    border-radius: 10px;
    border-width: 2px;
    margin: 5px;
    background-color: rgba(0, 0, 0, 0.025);
}

.envio {
    height: 500px;
}

.ventas-card-id {
    margin: 0;
    color: white;
    background-color: rgb(5, 80, 125);
    padding: 10px;
    width: 200px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}

.ventas-card-table-title {
    font-size: small;
    font-weight: bolder;
    margin: 2px;
    margin-top: 10px;
}

.ventas-card-table {
    max-height: 100px;
    height: 100px;
    overflow-y: scroll;
    width: 90%;
    border-style: solid;
    border-color: black;
    border-width: 1px;
    padding: 1px;
}

.retira-table {
    width: 95%;
}

.ventas-card-items {
    border-style: solid;
    border-color: black;
    border-width: 1px;
    padding: 5px;
    font-size: smaller;
    max-width: 100%;
    width: 100%;
    align-self: center;
    border-collapse: collapse;
}

.ventas-card-item {
    border-style: solid;
    border-width: 1px;
}

.ventas-card-title {
    width: 70%;
    border-right-style: solid;
    border-width: 1px;
    padding: 5px;
}

.ventas-card-price {
    width: 30%;
}

.ventas-card-data {
    width: 200px;
    max-height: 14px;
    overflow-y: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    /* number of lines to show */
    line-clamp: 1;
    -webkit-box-orient: vertical;
    border-width: 1px;
    padding: 5px;
    background-color: rgb(55, 130, 175, 0.25);
}

.retira-data {
    width: 225px;
}

.retira-title {
    width: 225px;
    font-weight: bold;
    background-color: white;
}

.total {
    background-color: rgb(55, 130, 175, 0.25);
    font-weight: bold;
}

.ventas-card-retira-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: center;
    width: 125px;
    height: 30px;
    margin-top: 15px;
    border-style: solid;
    border-color: black;
    background-color: rgba(5, 80, 125);
    color: white;
    border-radius: 10px;
    transition: 0.5s;
}

.despacho {
    margin: 0;
}

.seguimiento-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-style: solid;
    border-width: 1px;
    padding: 5px;
    margin-top: 10px;
    border-radius: 5px;
}

.seguimiento-form-input {
    margin: 5px;
    text-align: center;
    border-style: none;
    background-color: rgb(55, 130, 175, 0.15);
    padding: 5px;
    border-radius: 5px;
}

.seguimiento-form-input:focus {
    outline: none;
}

@media (min-width: 1000px) {
    .ventas-card-retira-button:hover {
        cursor: pointer;
        background-color: black;
    }
}

@media (max-width: 850px) {
    .ventas-card-table {
        padding: 5px;
    }

    .ventas-card-table-title {
        margin-top: 5px;
    }

    .ventas-card-item {
        width: 100%;
    }
}