.editorial-main-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    width: 100%;
}

.editorial-products-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.editorial-not-recos{
    margin: 0;
    font-weight: bold;
}

.editorial-img{
    width: 200px;
    border-radius: 15px;
}

.editorial-products-buttons{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.editorial-products-button{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 2px;
    width: 95px;
    height: 50px;
    max-width: 95px;
    max-height: 50px;
    font-size: large;
    border-style: solid;
    border-color: transparent;
    background-color: rgb(55, 125, 175);
    color: black;
    font-weight: bold;
    transition: 0.5s;
}

.editorial-products-button:disabled{
    background-color: gray;
    color: rgb(35, 35, 35);
}

.first{
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
}

.last{
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
}


.editorial-recos{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.editorial-recos-title{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: rgb(55, 125, 175, 0.5);
    max-width: 300px;
    max-height: 45px;
    width: 300px;
    height: 45px;
    color: rgba(0, 0, 0, 0.75);
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    margin-bottom: 0;
    width: 100%;
}

.editorial-recos-serie{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 2px;
    margin-left: 5px;
    margin-right: 5px;
    width: 100%;
}

.editorial-recos-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 850px;
}

.editorial-recos-main{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.editorial-recos-main-title{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 15px;
    margin-top: 50px;
    width: 95%;
    align-self: center;
    background-color: rgb(55, 125, 175);
    color: white;
    height: 50px;
    border-radius: 10px;
    border-style: solid;
    border-color: black;
}

.editorial-recos-grid{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    align-self: center;
    justify-content: flex-start;
    overflow: auto;
    max-width: 95vw;
    background-color: rgb(55, 125, 175, 0.5);
    padding: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 15px;
    margin-top: 0;
}

.editorial-recos-img{
    width: 150px;
    height: 200px;
    object-fit: cover;
    border-radius: 3px;
}

.editorial-special{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-width: 325px;
    max-width: 325px;
    background-color: rgb(55, 125, 175, 0.5);
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    padding: 10px;
}

.editorial-special-img{
    min-width: 150px;
    height: 200px;
    object-fit: cover;
    border-radius: 5px;
    margin-right: 5px;
}

.editorial-special-title{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    margin-left: 5px;
    padding: 5px;
    border-radius: 10px;
    height: 150px;
    font-size: medium;
}

.editorial-all-products{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.editorial-products-grid{
    display: grid;
    grid-template-columns: repeat(auto-fill, 160px);
    row-gap: 10px;
    column-gap: 5px;
    justify-content: center;
    min-width: 100%;
    max-width: 100%;
}

@media (min-width: 850px){

    .editorial-products-grid{
        width: 850px;
    }

    .special{
        display: grid;
        grid-template-columns: repeat(auto-fill, 350px);
        row-gap: 10px;
        column-gap: 5px;
        justify-content: center;
        width: 100%;
    }

    .editorial-products-button:hover{
        cursor: pointer;
        border-color: black;
    }

    .editorial-products-button:disabled:hover{
        cursor: default;
        border-color: transparent;
    }

    .editorial-recos-grid{
        padding: 10px;
        border-radius: 10px;
    }

    .editorial-recos-serie{
        padding: 2px;
        border-style: solid;
        margin: 1px;
        border-width: 3px;
        border-color: transparent;
        border-radius: 5px;
        transition: 0.5s;
    }

    .editorial-recos-serie:hover{
        cursor: pointer;
        border-color: rgb(55, 125, 175);
    }

    .editorial-special{
        border-style: solid;
        border-width: 3px;
        border-color: transparent;
        transition: 0.5s;
    }

    .editorial-special:hover{
        cursor: pointer;
        border-color: rgb(55, 125, 175);
    }
}

@media (max-width: 850px){
    .editorial-recos-container{
        width: fit-content;
    }
}