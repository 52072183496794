.product-main-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    max-width: 75%;
}

.main-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    padding: 15px;
    box-shadow: 0 0 3px black;
}

.product-main-column{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 5px;
}

.product-img{
    border-radius: 15px;
    min-width: 300px;
    max-width: 300px;
    height: 500px;
    object-fit: cover;
}

.product-info-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: rgb(55, 130, 175, 0.5);
    min-height: 100%;
    border-bottom-right-radius: 15px;
    border-top-right-radius: 15px;
    padding: 15px;
    height: 400px;
}

.product-title{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 5px;
    height: 50px;
    width: 100%;
    margin: 0;
}

.product-sinopsis{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    border-radius: 5px;
    height: 125px;
    width: 85%;
    background-color: rgb(255, 255, 255, 0.25);
    overflow-y: auto;
    padding: 10px;
}

.product-price-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.75);
    height: 75px;
    width: 275px;
    border-radius: 5px;
}

.product-price{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
    font-size: xx-large;
    font-weight: bolder;
    justify-self: center;
}

.product-old-price{
    margin: 0;
    color: rgb(200, 0, 0);
    text-decoration: line-through;
    font-weight: bold;
}

.product-buy-button{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgb(55, 130, 175);
    width: 300px;
    height: 50px;
    font-size: large;
    font-weight: bold;
    border-style: solid;
    border-color: transparent;
    margin-top: 15px;
    border-radius: 5px;
    transition: 0.25s;
}

.product-no-stock{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgb(200, 0, 0);
    width: 300px;
    height: 50px;
    font-size: large;
    font-weight: bold;
    border-style: solid;
    border-color: transparent;
    margin-top: 15px;
    border-radius: 5px;
    color: white;
}

.product-side-info-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: 25px;
    width: 650px;
    height: fit-content;
    padding-bottom: 15px;
    border-radius: 10px;
    border-style: solid;
    border-color: rgb(55, 130, 175);
    background-color: rgb(55, 130, 175, 0.1);
}

.product-side-info-main-title{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgb(55, 130, 175);
    margin-top: 0;
    width: 300px;
    height: 45px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.product-side-info{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.product-side-info-column{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    margin-right: 10px;
}

.product-side-info-row{
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-top: 1px;
    margin-bottom: 1px;
    padding: 5px;
    height: 50px;
}

.product-side-info-title{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: center;
    justify-self: center;
    width: 75px;
    font-weight: bold;
    font-size: medium;
    padding-left: 15px;
    padding-right: 15px;
}

.product-side-info-text{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    width: 150px;
    height: 45px;
    font-size: small;
    padding-left: 10px;
}

.side-info-link{
    transition: 0.5s;
}

.product-related-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
    width: 90%;
}

.product-related-title{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgb(55, 130, 175);
    width: 100%;
    height: 50px;
    border-radius: 10px;
}

.product-related{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.product-related-grid{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height: fit-content;
    padding: 10px;
    overflow-y: auto;
    background-color: rgb(105, 180, 225, 0.25);
    border-radius: 10px;
}

@media (min-width: 800px){
    .product-buy-button:hover{
        cursor: pointer;
        background-color: white;
        border-color: black;
    }

    .side-info-link:hover{
        cursor: pointer;
        background-color: rgba(55, 130, 175, 0.25);
    }
}

@media (max-width: 675px){
    .product-side-info-container{
        width: 90vw;
    }    

    .product-side-info{
        flex-direction: column;
    }
}

@media (max-width: 850px){    
    .main-container{
        flex-direction: column;
        width: fit-content;
        max-width: fit-content;
        padding: 5px;
        padding-top: 15px;
        padding-bottom: 2px;
        border-radius: 5px;
    }

    .product-main-column{
        margin: 0;
        margin-top: 3px;
        margin-bottom: 3px;
    }

    .product-img{
        border-radius: 5px;
    }

    .product-info-container{
        border-radius: 5px;
    }
}