.card-main-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 275px;
    border-style: solid;
    background-color: black;
    border-radius: 5px;
    margin-bottom: 5px;
    border-width: 3px;
    border-color: transparent;
    transition: 0.25s;
}

.card-img{
    width: 150px;
    min-width: 150px;
    height: 185px;
    min-height: 185px;
    object-fit: cover;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
}

.card-price{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    margin: auto;
    margin-left: -54px;
    width: 100px;
    height: 35px;
    background-color: white;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-style: solid;
    border-left-style: none;
    border-width: 2px;
    transition: 0.25s;
}

.card-price-p{
    font-weight: bold;
    margin: 0;
}

.card-price-p-old{
    color: red;
    text-decoration: line-through;
    margin: 0;
    font-size: smaller;
}

.card-info{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 150px;
    height: 140px;
    background-color: white;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    margin: 2px;
    margin-bottom: 0;
    transition: 0.25s;
}

.card-title{
    font-size: smaller;
    font-weight: bold;
    padding: 5px;
    margin: 0;
    max-height: 30px;
    display:inline-block;
    vertical-align: top;
    overflow-y: auto;
    overflow-x: hidden;
}

.card-title::-webkit-scrollbar {
    display: none;
}

.card-actions{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: rgb(5, 80, 125);
    width: 100%;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top-style: double;
    border-color: white;
    border-width: 4px;
    transition: 0.25s;
    height: 40px;
    max-height: 40px;
}

.no-stock{
    background-color: rgb(185, 0, 0);
}

.action-button{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: none;
    border-style: none;
    max-width: fit-content;
    height: fit-content;
    margin-left: 20px;
    margin-right: 20px;
}

.action-icon{
    font-size: 30px;
    color: white;
    transition: 0.25s;
}

.sin-stock-p{
    margin: 0;
    font-weight: bolder;
    color: white;
}

@media (min-width: 850px){
    .card-main-container:hover .card-info{
        cursor: pointer;
        background-color: rgb(105, 180, 225);
        border-color: rgb(105, 180, 225);
    }

    .card-main-container:hover .card-price{
        cursor: pointer;
        border-color: rgb(105, 180, 225);
    }

    .card-main-container:hover{
        cursor: pointer;
        background-color: rgb(105, 180, 225);
    }

    .card-main-container:hover .card-actions{
        cursor: pointer;
        border-color: rgb(105, 180, 225);
    }

    .action-button:hover .action-icon{
        cursor: pointer;
        color: rgb(155, 225, 255);
    }

    .action-button:hover{
        cursor: pointer;
    }
}

@media (max-width: 850px){
    .card-price{
        border-left-style: solid;
        border-left-color: white;
    }
}