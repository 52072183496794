.banners-main-container{
    display: flex;
    flex-direction: column;
    height: fit-content;
    justify-content: center;
}

.banner{
    align-self: center;
    width: auto;
    height: 400px;
    border-radius: 1em;
    border-style: solid;
    border-color: white;
}

@media (min-width: 1750px){
    .banners-main-container{
        margin-top: 6.5em;
        margin-bottom: 2.5em;
    }
}



#oculto{
    position: absolute;
    left: 0;
    right: 0;
}

#oculto-clon{
    position: absolute;
    left: 0;
    right: 0;
}

@media (max-width: 450px){
    .banners-main-container{
        display: none;
    }
}

@media (min-width: 450px) and (max-width: 650px){
    .banners-main-container{
        height: fit-content;
        justify-content: center;
    }
}

@media (min-width: 1750px){
    .banners-main-container{
        margin: 0;
        margin-top: 25px;
        margin-bottom: 25px;
    }

    .banner{
        width: auto;
        height: 550px;
    }
    
}