.editoriales-main-container{
    display: flex;
    flex-direction: row;
    align-self: flex-start;
    justify-content: space-between;
    align-items: center;
}

.editorial-card{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    /*
    border-style: solid;
    border-width: 2px;
    */
    box-shadow: 2px 2px 2px 2px gray;
    min-width: 115px;
    width: 115px;
    max-width: 115px;
    height: 175px;
    max-width: 120px;
    border-radius: 10px;
    margin: 5px;
    padding: 10px;
    transition: 0.5s;
}

.editorial-card-img{
    min-width: 100px;
    width: 100px;
    min-height: 100px;
    height: 100px;
    object-fit: contain;
    border-radius: 10px;
    background-color: white;
    transition: 0.15s;
}

.editorial-card-text-container{
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-end;
}

.editorial-card-text{
    font-weight: bold;
    font-size: small;
    justify-self: flex-start;
}

@media (min-width: 900px){
    .editorial-card:hover{
        cursor: pointer;
        border-color: rgb(5, 80, 125, 0.75);
        box-shadow: 2px 2px 2px 2px rgb(5, 80, 125, 0.75);
    }

    .editorial-card:hover .editorial-card-text-container{
        cursor: pointer;
        color: rgb(5, 80, 125, 0.75);
    }
}