.products-main-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    max-width: 95vw;
    width: 100%;
}

.products-recos-grid{
    display: grid;
    grid-template-columns: repeat(auto-fill, 500px);
    column-gap: 25px;
    justify-content: center;
    width: 100%;
    margin-top: 25px;
    padding-top: 25px;
    padding-bottom: 25px;
    border-radius: 5px;
}

.products-recos-grid-libro{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 25px;
    padding-top: 25px;
    padding-bottom: 25px;
    border-radius: 5px;
}

.products-editoriales-title-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 300px;
    align-self: center;
    background-color: rgb(5, 80, 125, 0.75);
    margin-bottom: 15px;
    margin-top: 25px;
    border-radius: 15px;
}

.products-editoriales-title{
    color: white;
}

.products-editoriales{
    display: flex;
    flex-direction: column;
    overflow: auto;
    align-items: flex-start;
    max-width: 100%;
}

@media (max-width: 500px){
    .products-recos-grid{
        grid-template-columns: repeat(auto-fill, 330px);
        row-gap: 25px;
        border-radius: 5px;
    }

    .products-recos-grid-libro{
        grid-template-columns: repeat(auto-fill, 330px);
        row-gap: 25px;
        border-radius: 5px;
    }
}