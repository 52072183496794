.ticket-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.ticket-table{
    width: 325px;
    max-width: 325px;
    border-style: solid;
    border-color: black;
    border-width: 1px;
    padding: 5px;
    font-size: smaller;
    align-self: center;
    border-collapse: collapse;
}

.ticket-item-title{
    border-style: solid;
    border-width: 1px;
    padding: 5px;
}

.ticket-item-price{
    border-style: solid;
    border-width: 1px;
    padding: 5px;
}

.ticket-item-desc{
    border-style: solid;
    border-width: 1px;
    padding: 5px;
}

.ticket-items-total{
    background-color: rgb(55, 130, 175, 0.5);
}