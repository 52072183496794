.ventas-main-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.ventas-buttons{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.ventas-button{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
    width: 200px;
    height: 45px;
    font-size: medium;
    font-weight: bold;
    border-radius: 0;
    border-style: solid;
    border-color: rgb(55, 130, 175);
    background-color: white;
    transition: 0.5s;
}

#retirar{
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

#despachar{
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.ventas-button:hover{
    cursor: pointer;
    background-color: rgb(55, 130, 175);
}

.ventas-grid{
    width: 100%;
    margin-top: 25px;
    display: grid;
    grid-template-columns: repeat(auto-fill, 250px);
    row-gap: 5px;
    column-gap: 10px;
    align-items: center;
    justify-items: center;
    justify-content: center;
}

@media (max-width: 850px){
    .ventas-buttons{
        flex-direction: column;
    }

    #retirar{
        border-radius: 0;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }
    
    #despachar{
        border-radius: 0;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
}