.footer-main-container{
    display: flex;
    flex-direction: column;
    height: 200px;
    margin: -8px;
    margin-top: 50px;
    width: 100vw;
    background-color: rgb(5, 80, 125);
    align-self: flex-end;
}

.footer-title-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    color: black;
    width: fit-content;
    align-self: center;
    padding: 10px;
    margin-top: 5px;
    border-radius: 10px;
    width: 300px;
}

.footer-title{
    margin: 0;
    margin-bottom: 1px;
}

.footer-subtitle{
    margin: 0;
    font-size: smaller;
}

.footer-info-container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-left: 5px;
    padding-top: 15px;
}

.footer-info{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0;
    margin-bottom: 2px;
    margin-top: 2px;
    color: white;
    text-align: left;
    align-self: flex-start;
    font-size: small;
}

.footer-icon{
    margin-right: 10px;
    font-size: large;
}

.info-ig{
    margin-top: 15px;
}

.ig{
    font-size: medium;
}

.footer-copyrigth-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2px;
    border-radius: 5px;
    width: fit-content;
    align-self: center;
    margin-bottom: 5px;
}

.footer-copyrigth{
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: rgba(255, 255, 255, 0.5);
}