.scard-main-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 275px;
    border-style: solid;
    background-color: black;
    border-radius: 5px;
    margin-bottom: 5px;
    border-width: 3px;
    border-color: transparent;
    transition: 0.25s;
}

.scard-img{
    width: 150px;
    min-width: 150px;
    height: 225px;
    min-height: 185px;
    object-fit: cover;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
}

.scard-info{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 50px;
    background-color: white;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    margin: 2px;
    margin-bottom: 0;
    transition: 0.25s;
}

.scard-title{
    font-size: smaller;
    font-weight: bold;
    padding: 5px;
    margin: 0;
    max-height: 30px;
    display:inline-block;
    vertical-align: top;
    overflow-y: auto;
    overflow-x: hidden;
}

.scard-title::-webkit-scrollbar {
    display: none;
}

@media (min-width: 850px){
    .scard-main-container:hover .scard-info{
        cursor: pointer;
        background-color: rgb(105, 180, 225);
        border-color: rgb(105, 180, 225);
    }

    .scard-main-container:hover{
        cursor: pointer;
        background-color: rgb(105, 180, 225);
    }
}